<template>
  <v-card class="mx-auto" max-width="580" flat>
    <v-card dark flat>
      <v-card-title class="pa-2 indigo">
        <h3 class="text-h6 font-weight-light text-center grow">
          {{ title }}
        </h3>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-dialog v-model="dialog" persistent max-width="550">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              absolute
              bottom
              v-bind="attrs"
              v-on="on"
              right
              fab
              color="green"
            >
              <v-icon>mdi-file-document-plus</v-icon>
            </v-btn>
          </template>
          <v-card width="600">
            <v-card-title class="text-h5">
              Ajouter un commentaire
            </v-card-title>
            <v-card-text>
              <v-select
                v-model="comment_type"
                label="Type"
                :rules="[(v) => !!v || 'Type obligatoire']"
                :items="comment_types"
                :item-value="'id'"
                :item-text="'label'"
                v-if="ct"
              >
              </v-select>
              <v-textarea
                v-model="comment"
                :rules="[(v) => !!v || 'Commentaire obligatoire']"
                label="Commentaire"
              >
              </v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :loading="progress" color="primary" @click="save_comment">
                Enregistrer
              </v-btn>
              <v-btn color="error" @click="dialog = false"> Annuler </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-card>
    <v-card-text>
      <v-timeline align-top dense v-if="comments.length > 0">
        <v-timeline-item
          v-for="(item, i) in comments"
          :key="i"
          fill-dot
          :icon="item.icon"
          :color="item.color"
          small
        >
          <v-card flat outlined>
            <v-card-title>{{ item.user_name }}</v-card-title>
            <v-card-subtitle>{{
              datefr(item.created_at, true)
            }}</v-card-subtitle>
            <v-card-text>
              <strong v-html="item.comment.replace(/\n/g, '<br>')"></strong>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<script>
import CREATE_NOTE from "../graphql/CREATE_NOTE.gql";
export default {
  props: {
    title: String,
    comments: Array,
    document_type: Number,
    doc_id: Number,
    ct: { type: Boolean, default: true },
  },
  data() {
    return {
      dialog: false,
      comment_type: "4",
      comment: null,
      valid: true,
      progress: false,
      comment_types: [
        { id: "1", label: "Phone", icon: "mdi-phone-classic", color: "gray" },
        { id: "2", label: "Email", icon: "mdi-email", color: "green" },
        {
          id: "3",
          label: "Meeting",
          icon: "mdi-account-multiple",
          color: "purple",
        },
        {
          id: "4",
          label: "Info",
          icon: "mdi-account-tie-voice",
          color: "blue",
        },
        { id: "5", label: "Reserve", icon: "mdi-calendar-clock", color: "red" },
      ],
    };
  },
  watch: {
    comments: {
      handler() {
        this.comments.forEach((element) => {
          let i = this.comment_types.findIndex(
            (elm) => elm.id == element.comment_type
          );
          if (i >= 0) {
            element.icon = this.comment_types[i].icon;
            element.color = this.comment_types[i].color;
          }
        });
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    save_ext_comment(comment, comment_type) {
      this.comment = comment;
      this.comment_type = comment_type;
      this.save_comment();
      // Use the parameters to perform the task
      // Example:
      // console.log(`Param1: ${param1}, Param2: ${param2}`);
    },

    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save_comment() {
      if (this.$refs.form.validate()) {
        let v = {
          note: {
            document_type: this.document_type,
            document_id: parseInt(this.doc_id),
            comment: this.comment,
            comment_type: parseInt(this.comment_type),
            create_uid: parseInt(this.$store.state.me.id),
            write_uid: parseInt(this.$store.state.me.id),
          },
        };
        let id = await this.maj(CREATE_NOTE, v);
        this.comments.push({
          id: id,
          comment: this.comment,
          user_name: this.$store.state.me.name,
          comment_type: parseInt(this.comment_type),
          create_uid: parseInt(this.$store.state.me.id),
          created_at: this.$store.state.today,
        });
        this.$emit("note_added");
        this.comment = null;
        this.dialog = false;
      }
    },
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
  },
};
</script>
